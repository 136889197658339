@import 'ds/styles/includes/index.scss';

.stacked-xxs {
    margin-bottom: 2px;
}
.stacked-xs {
    margin-bottom: 4px;
}
.stacked-sm {
    margin-bottom: 8px;
}
.stacked-md {
    margin-bottom: 16px;
}
.stacked-lg {
    margin-bottom: 32px;
}
.stacked-xl {
    margin-bottom: 64px;
}
.stacked-xxl {
    margin-bottom: 128px;
}

.inline-xs {
    margin-right: 4px;
}
.inline-sm {
    margin-right: 8px;
}
.inline-md {
    margin-right: 16px;
}
.inline-lg {
    margin-right: 32px;
}
.inline-xl {
    margin-right: 64px;
}

.inset-xs {
    padding: 4px;
}
.inset-sm {
    padding: 8px;
}
.inset-md {
    padding: 16px;
}
.inset-lg {
    padding: 32px;
}
.inset-xl {
    padding: 64px;
}

.inset-x-xs {
    padding-right: 4px;
    padding-left: 4px;
}
.inset-x-sm {
    padding-right: 8px;
    padding-left: 8px;
}
.inset-x-md {
    padding-right: 16px;
    padding-left: 16px;
}
.inset-x-lg {
    padding-right: 32px;
    padding-left: 32px;
}
.inset-x-xl {
    padding-right: 64px;
    padding-left: 64px;
}

.inset-y-xxs {
    margin-top: 2px;
    margin-bottom: 2px;
}
.inset-y-xs {
    margin-top: 4px;
    margin-bottom: 4px;
}
.inset-y-sm {
    margin-top: 8px;
    margin-bottom: 8px;
}
.inset-y-md {
    margin-top: 16px;
    margin-bottom: 16px;
}
.inset-y-lg {
    margin-top: 32px;
    margin-bottom: 32px;
}
.inset-y-xl {
    margin-top: 64px;
    margin-bottom: 64px;
}

.inline {
    display: inline-block;
}
.top {
    vertical-align: top;
}
.middle {
    vertical-align: middle;
}

.no-margin {
    margin: 0px !important;
}
.no-padding {
    padding: 0px !important;
}

.padding-top-sm {
    padding-top: 8px;
}
.padding-top-md {
    padding-top: 16px;
}
.padding-top-lg {
    padding-top: 32px;
}

.padding-bottom-sm {
    padding-bottom: 8px;
}
.padding-bottom-md {
    padding-bottom: 16px;
}
.padding-bottom-lg {
    padding-bottom: 32px;
}

.ml-xs {
    margin-left: 4px;
}
.ml-sm {
    margin-left: 8px;
}
