@import 'ds/styles/includes/index.scss';

.banner {
    position: relative;
}

.callout {
    width: 5px;
    position: absolute;
    border-radius: 0px 20px 20px 0px;
    top: 16px;
    bottom: 16px;
}

.primary {
    background-color: var(--primary-500);
}

.secondary {
    background-color: var(--neutral-300);
}

.success {
    background-color: var(--success-500);
}

.warning {
    background-color: var(--warning-500);
}

.danger {
    background-color: var(--danger-500);
}
