@import 'ds/styles/includes/index.scss';

.card {
    border-radius: 20px;
    background-color: var(--surface-forefront);
}

.primary {
    color: var(--primary-500);
    background-color: var(--primary-100);
    border: 1px solid var(--primary-100);
}

.secondary {
    border: 1px solid var(--neutral-300);
}

.success {
    color: var(--success-700);
    background-color: var(--success-200);
}

.warning {
    color: var(--warning-700);
    background-color: var(--warning-200);
}

.danger {
    color: var(--danger-700);
    background-color: var(--danger-200);
}

.border {
    border: 1px solid var(--neutral-200);
}

.secondaryGray {
    color: var(--neutral-700);
    background-color: var(--neutral-100);
}

.primaryAqua {
    color: var(--primary-800);
    background-color: var(--aqua-color);
}

.clickable {
    cursor: pointer;
    // border: 1px solid transparent;
    // &:hover {
    //     border: 1px solid var(--neutral-300);
    // }
}
