// Chart colors:
// https://www.schemecolor.com/modern-red-blue-green.php

$neutral-000: #fafbfb;
$neutral-100: #f2f3f5;
$neutral-200: #e9ecef;
$neutral-300: #dee2e6;
$neutral-500: #adb5bd;
$neutral-600: #6c757d;
$neutral-700: #495057;
$neutral-400: #ced4da;
$neutral-800: #343a40;
$neutral-900: #212325;
$neutral-999: #121212;

$blue: #2f81f7; // #0d6efd; //007bff
$indigo: #6610f2;
$purple: #743aba;
$pink: #d63384;
$red: #f85149; //#ff0000; //#dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #238636; // #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$aqua: #eff6ff;
$emerald: #22c55d;

@function tint-color($color, $weight) {
    @return mix(white, $color, $weight);
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
    @return mix(black, $color, $weight);
}

$primary-100: tint-color($blue, 80%) !default;
$primary-200: tint-color($blue, 60%) !default;
$primary-300: tint-color($blue, 40%) !default;
$primary-400: tint-color($blue, 20%) !default;
$primary-500: $blue !default;
$primary-600: shade-color($blue, 20%) !default;
$primary-700: shade-color($blue, 40%) !default;
$primary-800: shade-color($blue, 60%) !default;
$primary-900: shade-color($blue, 80%) !default;

$secondary-100: tint-color($indigo, 80%) !default;
$secondary-200: tint-color($indigo, 60%) !default;
$secondary-300: tint-color($indigo, 40%) !default;
$secondary-400: tint-color($indigo, 20%) !default;
$secondary-500: $indigo !default;
$secondary-600: shade-color($indigo, 20%) !default;
$secondary-700: shade-color($indigo, 40%) !default;
$secondary-800: shade-color($indigo, 60%) !default;
$secondary-900: shade-color($indigo, 80%) !default;

$danger-100: tint-color($red, 80%) !default;
$danger-200: tint-color($red, 60%) !default;
$danger-300: tint-color($red, 40%) !default;
$danger-400: tint-color($red, 20%) !default;
$danger-500: $red !default;
$danger-600: shade-color($red, 20%) !default;
$danger-700: shade-color($red, 40%) !default;
$danger-800: shade-color($red, 60%) !default;
$danger-900: shade-color($red, 80%) !default;

$warning-100: tint-color($orange, 80%) !default;
$warning-200: tint-color($orange, 60%) !default;
$warning-300: tint-color($orange, 40%) !default;
$warning-400: tint-color($orange, 20%) !default;
$warning-500: $orange !default;
$warning-600: shade-color($orange, 20%) !default;
$warning-700: shade-color($orange, 40%) !default;
$warning-800: shade-color($orange, 60%) !default;
$warning-900: shade-color($orange, 80%) !default;

$success-100: tint-color($green, 80%) !default;
$success-200: tint-color($green, 60%) !default;
$success-300: tint-color($green, 40%) !default;
$success-400: tint-color($green, 20%) !default;
$success-500: $green !default;
$success-600: shade-color($green, 20%) !default;
$success-700: shade-color($green, 40%) !default;
$success-800: shade-color($green, 60%) !default;
$success-900: shade-color($green, 80%) !default;

// Simple color tokens
$aqua-base: $aqua !default;
$aqua-dark: shade-color($aqua, 80%) !default;

$emerald-base: $emerald !default; // works for both dark and light themes
$purple-base: $purple !default;
