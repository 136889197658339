@import 'ds/styles/includes/index.scss';
@import './datepicker/datepicker.scss';

html {
    height: 100%;
}
body {
    min-height: 100%;
    background-color: var(--surface-backdrop);
    color: var(--neutral-800);
}
#__next,
.h-100p {
    height: 100vh;
}

:focus-visible {
    outline: none;
}

button,
input,
optgroup,
select,
textarea,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        color: inherit;
    }
}

pre {
    margin: 0px;
}
img {
    max-width: 100%;
}

blockquote {
    font-size: 32px;
}

.block {
    display: block;
}

.center {
    margin: auto;
    text-align: center;
}

.center-text {
    text-align: center;
}

.pull-right {
    text-align: right;
}
.right {
    text-align: right;
}
.right-md-and-up {
    @include breakpoint(md-and-up) {
        text-align: right;
    }
}

.right-sm-and-up {
    @include breakpoint(sm-and-up) {
        text-align: right;
    }
}

.vertical-center {
    margin: auto;
}

.clickable-item {
    transition-property: all;
    transition-duration: 0.24s;
    transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
    padding: 12px;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: var(--neutral-200);
        border-radius: 50%;
    }
}

.full-doc {
    width: 100%;
    min-height: 800px;
}

.tall {
    min-height: 1000px;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y {
    overflow-y: auto;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.overflow-wrapper {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.flex-overflow-x {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
}
.flex-overflow-x div {
    flex: 0 0 auto;
}

.pointer {
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.visible {
    visibility: visible !important;
}
.hidden {
    visibility: hidden !important;
}

.ease {
    transition-property: all;
    transition-duration: 0.24s;
    transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
}

.strong {
    font-weight: 700;
}

.semi-strong {
    font-weight: 600;
}

.strikethrough {
    text-decoration: line-through;
}

.italic {
    font-style: italic;
}

.small {
    font-size: 12px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-24 {
    font-size: 24px;
}
.fs-26 {
    font-size: 26px;
}

.lh-12 {
    line-height: 12px;
}
.lh-14 {
    line-height: 14px;
}
.lh-16 {
    line-height: 16px;
}
.lh-20 {
    line-height: 20px;
}
.lh-24 {
    line-height: 24px;
}
.h-14 {
    height: 14px;
}

.pre-wrap {
    white-space: pre-wrap;
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.toast-container {
    border-radius: 12px !important;
}
:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #0d6efd;
    --toastify-color-success: #238636; // #07bc0c;
    --toastify-color-warning: #ffc107;
    --toastify-color-error: #ff0000;
}

.w-200 {
    width: 200px;
}
.w-300 {
    width: 300px;
}
.w-400 {
    width: 400px;
}

.min-w-240 {
    min-width: 240px;
}

.mw-80 {
    max-width: 80px;
}

.mw-200 {
    max-width: 200px;
}
.mw-250 {
    max-width: 250px;
}
.mw-350 {
    max-width: 350px;
}

.mw-400 {
    max-width: 400px !important;
}

.box-shadow {
    border: none;
    box-shadow: $box-shadow;
}

.recharts-default-tooltip {
    border: 1px solid var(--neutral-200) !important;
    border: none;
    border-radius: $primary-radius;
    background-color: var(--neutral-000) !important;
    line-height: 14px;
}

.react-multi-email {
    padding: 2px 6px !important;
    border-radius: 8px !important;
    background-color: transparent !important;
    border-color: var(--neutral-300) !important;
    > input {
        color: var(--neutral-800);
        background-color: transparent;
    }
    &.focused {
        border-color: #0d6efd !important;
        box-shadow: inset 0 0 0 1px #0d6efd !important;
    }
    .data-labels {
        flex-wrap: wrap;
    }
}

.csv-link {
    color: var(--primary-500);
    &:hover {
        color: var(--primary-300);
        text-decoration: underline;
    }
}

ion-icon {
    pointer-events: none;
    display: block;
}

.position-relative {
    position: relative;
}

.position-abs {
    position: absolute;
}

.text-no-transform {
    text-transform: none;
}

.primary-text {
    color: var(--primary-500);
}

.warning-text {
    color: var(--warning-500);
}

.border-bottom-neutral {
    border-bottom: 1px solid var(--neutral-200);
}

.border-top-neutral-200 {
    border-top: 1px solid var(--neutral-200);
}

.border-top-neutral-500 {
    border-top: 1px solid var(--neutral-500);
}

.rounded-sm {
    border-radius: 8px !important;
}

.rounded-md {
    border-radius: 12px !important;
}

.rounded-lg {
    border-radius: 20px !important;
}
