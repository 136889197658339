@import 'ds/styles/includes/index.scss';

// @font-face {
//     font-family: 'EB Garamond';
//     src: url('/fonts/EBGaramond-VariableFont_wght.ttf')
//         format('truetype-variations');
//     font-weight: 1 999;
// }

// @font-face {
//     font-family: 'EB Garamond';
//     src: url('/fonts/EBGaramond-Italic-VariableFont_wght.ttf')
//         format('truetype-variations');
//     font-weight: 1 999;
//     font-style: italic;
// }

body,
input {
    -webkit-font-smoothing: antialiased;
    font-family: $font-family;
    line-height: 20px;
    font-size: 14px;
}

button {
    -webkit-font-smoothing: antialiased;
    font-family: $font-family;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    -webkit-font-smoothing: antialiased;
    // font-family: 'Helvetica Neue', Arial, serif;
    font-family: $font-family;
    letter-spacing: 0.5px;
    margin: 8px 0px 8px 0px;
}

h1 {
    font-size: 32px;
    line-height: 34px;
}

h2 {
    font-size: 26px;
    line-height: 28px;
}

h3 {
    font-size: 20px;
    line-height: 24px;
}

h4 {
    font-size: 18px;
    line-height: 20px;
}

h5 {
    font-size: 16px;
    line-height: 18px;
}
