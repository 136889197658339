@import 'ds/styles/includes/index.scss';

:root {
    --neutral-000: #{$neutral-000};
    --neutral-100: #{$neutral-100};
    --neutral-200: #{$neutral-200};
    --neutral-300: #{$neutral-300};
    --neutral-400: #{$neutral-400};
    --neutral-500: #{$neutral-500};
    --neutral-600: #{$neutral-600};
    --neutral-700: #{$neutral-700};
    --neutral-800: #{$neutral-800};
    --neutral-900: #{$neutral-900};
    --neutral-999: #{$neutral-999};
    --primary-100: #{$primary-100};
    --primary-200: #{$primary-200};
    --primary-300: #{$primary-300};
    --primary-400: #{$primary-400};
    --primary-500: #{$primary-500};
    --primary-600: #{$primary-600};
    --primary-700: #{$primary-700};
    --primary-800: #{$primary-800};
    --primary-900: #{$primary-900};
    --secondary-100: #{$secondary-100};
    --secondary-200: #{$secondary-200};
    --secondary-300: #{$secondary-300};
    --secondary-400: #{$secondary-400};
    --secondary-500: #{$secondary-500};
    --secondary-600: #{$secondary-600};
    --secondary-700: #{$secondary-700};
    --secondary-800: #{$secondary-800};
    --secondary-900: #{$secondary-900};
    --danger-100: #{$danger-100};
    --danger-200: #{$danger-200};
    --danger-300: #{$danger-300};
    --danger-400: #{$danger-400};
    --danger-500: #{$danger-500};
    --danger-600: #{$danger-600};
    --danger-700: #{$danger-700};
    --danger-800: #{$danger-800};
    --danger-900: #{$danger-900};
    --warning-100: #{$warning-100};
    --warning-200: #{$warning-200};
    --warning-300: #{$warning-300};
    --warning-400: #{$warning-400};
    --warning-500: #{$warning-500};
    --warning-600: #{$warning-600};
    --warning-700: #{$warning-700};
    --warning-800: #{$warning-800};
    --warning-900: #{$warning-900};
    --success-100: #{$success-100};
    --success-200: #{$success-200};
    --success-300: #{$success-300};
    --success-400: #{$success-400};
    --success-500: #{$success-500};
    --success-600: #{$success-600};
    --success-700: #{$success-700};
    --success-800: #{$success-800};
    --success-900: #{$success-900};

    --logo-url: url('/images/logo-horizontal-boxed.svg');
    --color-overlay: rgba(0, 0, 0, 0.6);
    --surface-backdrop: #{$neutral-100};
    --surface-forefront: #{$neutral-000};

    --dark-color: #{$neutral-800};
    --light-color: #{$neutral-200};

    --aqua-color: #{$aqua-base};
    --emerald-color: #{$emerald-base};
    --purple-color: #{$purple-base};
}

[data-theme='dark'] {
    // Reversed
    --neutral-000: #{$neutral-999};
    --neutral-100: #{$neutral-900};
    --neutral-200: #{$neutral-800};
    --neutral-300: #{$neutral-700};
    --neutral-400: #{$neutral-600};
    --neutral-500: #{$neutral-500};
    --neutral-600: #{$neutral-400};
    --neutral-700: #{$neutral-300};
    --neutral-800: #{$neutral-200};
    --neutral-900: #{$neutral-100};
    --neutral-999: #{$neutral-000};

    --primary-100: #{$primary-900};
    --primary-200: #{$primary-800};
    --primary-300: #{$primary-700};
    --primary-400: #{$primary-600};
    --primary-500: #{$primary-500};
    --primary-600: #{$primary-400};
    --primary-700: #{$primary-300};
    --primary-800: #{$primary-200};
    --primary-900: #{$primary-100};

    --secondary-100: #{$secondary-900};
    --secondary-200: #{$secondary-800};
    --secondary-300: #{$secondary-700};
    --secondary-400: #{$secondary-600};
    --secondary-500: #{$secondary-500};
    --secondary-600: #{$secondary-400};
    --secondary-700: #{$secondary-300};
    --secondary-800: #{$secondary-200};
    --secondary-900: #{$secondary-100};
    --danger-100: #{$danger-900};
    --danger-200: #{$danger-800};
    --danger-300: #{$danger-700};
    --danger-400: #{$danger-600};
    --danger-500: #{$danger-500};
    --danger-600: #{$danger-400};
    --danger-700: #{$danger-300};
    --danger-800: #{$danger-200};
    --danger-900: #{$danger-100};
    --warning-100: #{$warning-900};
    --warning-200: #{$warning-800};
    --warning-300: #{$warning-700};
    --warning-400: #{$warning-600};
    --warning-500: #{$warning-500};
    --warning-600: #{$warning-400};
    --warning-700: #{$warning-300};
    --warning-800: #{$warning-200};
    --warning-900: #{$warning-100};
    --success-100: #{$success-900};
    --success-200: #{$success-800};
    --success-300: #{$success-700};
    --success-400: #{$success-600};
    --success-500: #{$success-500};
    --success-600: #{$success-400};
    --success-700: #{$success-300};
    --success-800: #{$success-200};
    --success-900: #{$success-100};

    --logo-url: url('/images/logo-horizontal-boxed-white.svg');
    --color-overlay: rgba(0, 0, 0, 0.8);
    --surface-backdrop: #000;
    --surface-forefront: #1b1b1b;

    --dark-color: #{$neutral-800};
    --light-color: #{$neutral-200};

    --aqua-color: #{$aqua-dark};
    --emerald-color: #{$emerald-base};
    --purple-color: #{$purple-base};
}

.surface-forefront {
    color: var(--surface-forefront);
}

.bg-surface-forefront {
    background-color: var(--surface-forefront);
}

.neutral-000 {
    color: var(--neutral-000);
}
.neutral-100 {
    color: var(--neutral-100);
}
.neutral-200 {
    color: var(--neutral-200);
}
.neutral-300 {
    color: var(--neutral-300);
}
.neutral-400 {
    color: var(--neutral-400);
}
.neutral-500 {
    color: var(--neutral-500);
}
.neutral-600 {
    color: var(--neutral-600);
}
.neutral-700 {
    color: var(--neutral-700);
}
.neutral-800 {
    color: var(--neutral-800);
}
.neutral-900 {
    color: var(--neutral-900);
}
.neutral-999 {
    color: var(--neutral-999);
}

.primary-100 {
    color: var(--primary-100);
}
.primary-200 {
    color: var(--primary-200);
}
.primary-300 {
    color: var(--primary-300);
}
.primary-400 {
    color: var(--primary-400);
}
.primary-500 {
    color: var(--primary-500);
}
.primary-600 {
    color: var(--primary-600);
}
.primary-700 {
    color: var(--primary-700);
}
.primary-800 {
    color: var(--primary-800);
}
.primary-900 {
    color: var(--primary-900);
}

.secondary-100 {
    color: var(--secondary-100);
}
.secondary-200 {
    color: var(--secondary-200);
}
.secondary-300 {
    color: var(--secondary-300);
}
.secondary-400 {
    color: var(--secondary-400);
}
.secondary-500 {
    color: var(--secondary-500);
}
.secondary-600 {
    color: var(--secondary-600);
}
.secondary-700 {
    color: var(--secondary-700);
}
.secondary-800 {
    color: var(--secondary-800);
}
.secondary-900 {
    color: var(--secondary-900);
}

.success-100 {
    color: var(--success-100);
}
.success-200 {
    color: var(--success-200);
}
.success-300 {
    color: var(--success-300);
}
.success-400 {
    color: var(--success-400);
}
.success-500 {
    color: var(--success-500);
}
.success-600 {
    color: var(--success-600);
}
.success-700 {
    color: var(--success-700);
}
.success-800 {
    color: var(--success-800);
}
.success-900 {
    color: var(--success-900);
}

.warning-100 {
    color: var(--warning-100);
}
.warning-200 {
    color: var(--warning-200);
}
.warning-300 {
    color: var(--warning-300);
}
.warning-400 {
    color: var(--warning-400);
}
.warning-500 {
    color: var(--warning-500);
}
.warning-600 {
    color: var(--warning-600);
}
.warning-700 {
    color: var(--warning-700);
}
.warning-800 {
    color: var(--warning-800);
}
.warning-900 {
    color: var(--warning-900);
}

.danger-100 {
    color: var(--danger-100);
}
.danger-200 {
    color: var(--danger-200);
}
.danger-300 {
    color: var(--danger-300);
}
.danger-400 {
    color: var(--danger-400);
}
.danger-500 {
    color: var(--danger-500);
}
.danger-600 {
    color: var(--danger-600);
}
.danger-700 {
    color: var(--danger-700);
}
.danger-800 {
    color: var(--danger-800);
}
.danger-900 {
    color: var(--danger-900);
}

.aqua-color {
    color: var(--aqua-color);
}
.emerald-color {
    color: var(--emerald-color);
}
.purple-color {
    color: var(--purple-color);
}

.bg-primary-100 {
    background-color: var(--primary-100);
}
.bg-primary-200 {
    background-color: var(--primary-200);
}
.bg-primary-300 {
    background-color: var(--primary-300);
}
.bg-primary-400 {
    background-color: var(--primary-400);
}
.bg-primary-500 {
    background-color: var(--primary-500);
}
.bg-primary-600 {
    background-color: var(--primary-600);
}
.bg-primary-700 {
    background-color: var(--primary-700);
}
.bg-primary-800 {
    background-color: var(--primary-800);
}
.bg-primary-900 {
    background-color: var(--primary-900);
}

.bg-secondary-100 {
    background-color: var(--secondary-100);
}
.bg-secondary-200 {
    background-color: var(--secondary-200);
}
.bg-secondary-300 {
    background-color: var(--secondary-300);
}
.bg-secondary-400 {
    background-color: var(--secondary-400);
}
.bg-secondary-500 {
    background-color: var(--secondary-500);
}
.bg-secondary-600 {
    background-color: var(--secondary-600);
}
.bg-secondary-700 {
    background-color: var(--secondary-700);
}
.bg-secondary-800 {
    background-color: var(--secondary-800);
}
.bg-secondary-900 {
    background-color: var(--secondary-900);
}

.bg-success-100 {
    background-color: var(--success-100);
}
.bg-success-200 {
    background-color: var(--success-200);
}
.bg-success-300 {
    background-color: var(--success-300);
}
.bg-success-400 {
    background-color: var(--success-400);
}
.bg-success-500 {
    background-color: var(--success-500);
}
.bg-success-600 {
    background-color: var(--success-600);
}
.bg-success-700 {
    background-color: var(--success-700);
}
.bg-success-800 {
    background-color: var(--success-800);
}
.bg-success-900 {
    background-color: var(--success-900);
}

.bg-warning-100 {
    background-color: var(--warning-100);
}
.bg-warning-200 {
    background-color: var(--warning-200);
}
.bg-warning-300 {
    background-color: var(--warning-300);
}
.bg-warning-400 {
    background-color: var(--warning-400);
}
.bg-warning-500 {
    background-color: var(--warning-500);
}
.bg-warning-600 {
    background-color: var(--warning-600);
}
.bg-warning-700 {
    background-color: var(--warning-700);
}
.bg-warning-800 {
    background-color: var(--warning-800);
}
.bg-warning-900 {
    background-color: var(--warning-900);
}

.bg-danger-100 {
    background-color: var(--danger-100);
}
.bg-danger-200 {
    background-color: var(--danger-200);
}
.bg-danger-300 {
    background-color: var(--danger-300);
}
.bg-danger-400 {
    background-color: var(--danger-400);
}
.bg-danger-500 {
    background-color: var(--danger-500);
}
.bg-danger-600 {
    background-color: var(--danger-600);
}
.bg-danger-700 {
    background-color: var(--danger-700);
}
.bg-danger-800 {
    background-color: var(--danger-800);
}
.bg-danger-900 {
    background-color: var(--danger-900);
}

.bg-neutral-000 {
    background-color: var(--neutral-000);
}
.bg-neutral-100 {
    background-color: var(--neutral-100);
}
.bg-neutral-200 {
    background-color: var(--neutral-200);
}
.bg-neutral-300 {
    background-color: var(--neutral-300);
}
.bg-neutral-400 {
    background-color: var(--neutral-400);
}
.bg-neutral-500 {
    background-color: var(--neutral-500);
}
.bg-neutral-600 {
    background-color: var(--neutral-600);
}
.bg-neutral-700 {
    background-color: var(--neutral-700);
}
.bg-neutral-800 {
    background-color: var(--neutral-800);
}
.bg-neutral-900 {
    background-color: var(--neutral-900);
}
