$primary-radius: 8px;
$font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
$box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
// box-shadow: 0 0 0 1px rgba(136, 152, 170, 0.1019607843),
//     0 15px 35px rgba(49, 49, 93, 0.1019607843),
//     0 5px 15px rgba(0, 0, 0, 0.0784313725);

// box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
// box-shadow: 0 0 0 1px #8898aa1a, 0 15px 35px #31315d1a, 0 5px 15px #00000014;
